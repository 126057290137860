import React, { useState } from "react";
import { Lightbox } from "./Lightbox";
import { GalleryImage, GalleryRow } from "./GalleryRow";
import { MOBILE_BREAK } from "./App";

type GalleryGridProps = {
  galleryRows: GalleryImage[][];
  showDescription?: boolean;
};

export function GalleryGrid(props: GalleryGridProps) {
  const [rowsLoaded, setRowsLoaded] = useState(new Array(props.galleryRows.length))
  const [lightboxShown, setLightboxShown] = useState(false);
  const [lightboxInitialIndex, setLightboxInitialIndex] = useState(0);
  const flattenedImageSrcs = props.galleryRows.flat();

  var maxRowLoaded = -1;
  for (let i = 0; i < props.galleryRows.length; i++) {
    if (!rowsLoaded[i]) {
      break;
    }
    maxRowLoaded = i;
  }

  const onRowLoaded = (rowIndex: number) => {
    const newRowsLoaded = [...rowsLoaded];
    newRowsLoaded[rowIndex] = true;
    setRowsLoaded(newRowsLoaded);
  }

  const imageClicked = (absoluteIndex: number) => {
    if (window.innerWidth < MOBILE_BREAK) {
      // no lightbox on mobile
      return;
    }

    setLightboxShown(true);
    setLightboxInitialIndex(absoluteIndex);
  };

  const imageClickedWithRowAndItem = (rowIndex: number, itemIndex: number) => {
    // convert row and item index to absolute index
    let absoluteIndex = 0;
    for (let i = 0; i < rowIndex; i++) {
      absoluteIndex += props.galleryRows[i].length;
    }
    absoluteIndex += itemIndex;
    imageClicked(absoluteIndex);
  };

  const closeLightbox = () => {
    setLightboxShown(false);
  };

  return (
    <>
      {props.galleryRows.map((items: GalleryImage[], rowIndex: number) => 
        <GalleryRow
          key={rowIndex}
          items={items}
          shown={maxRowLoaded >= rowIndex - 1} // show up to the next loading row
          onLoaded={() => onRowLoaded(rowIndex)}
          showDescription={props.showDescription}
          imageClicked={(itemIndex: number) => imageClickedWithRowAndItem(rowIndex, itemIndex)}
          />
      )}
      {lightboxShown && (
        <Lightbox
          items={flattenedImageSrcs}
          initialIndex={lightboxInitialIndex}
          closeLightbox={closeLightbox} />
      )}
    </>
  );
}
